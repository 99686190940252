import { ThemeCss } from "gatsby-interface"
import * as React from "react"
import { sectionCss } from "../style-utils"
import { getCtas } from "../utils"
import { Ctas } from "./Ctas"
import { SplitLink as Link } from "components/links/SplitLink"

export interface SubnavigationProps {
  ctas: Ctas[]
}

const subnavCss: ThemeCss = theme => ({
  display: `flex`,
  flexDirection: `column`,
  flexWrap: `wrap`,
  gap: theme.space[8],
  justifyContent: `space-evenly`,

  [theme.mediaQueries.phablet]: {
    flexDirection: `row`,
  },
})

const linkCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[2],
  justifyContent: `center`,
  lineHeight: theme.lineHeights.dense,

  [theme.mediaQueries.tablet]: {
    fontSize: theme.fontSizes[3],
  },
})

export function Subnavigation({ ctas }: SubnavigationProps): JSX.Element {
  return (
    <section css={sectionCss}>
      <div css={subnavCss}>
        {ctas.map(cta => {
          return (
            <Link key={cta.title} to={cta.to} variant="SIMPLE" css={linkCss}>
              {cta.title}
            </Link>
          )
        })}
      </div>
    </section>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapProps = (entry: any): SubnavigationProps => {
  // Pull the Content, Items, and Appearance blocks out of the Section block that is passed in
  const { content } = entry

  // Normalize the data
  const ctas = getCtas(content)

  // Return the props that will be passed to FeatureGrid
  return { ctas }
}
