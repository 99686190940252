import * as React from "react"
import * as sanitizeHTML from "sanitize-html"
import { Heading, LinkButton, Text, Theme } from "gatsby-interface"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { itemCopyCss, itemTitleCss } from "../style-utils"
import { ColorSchemeCss } from "../color-schemes"
import VideoEmbed from "./VideoEmbed"
import { InterpolationWithTheme } from "@emotion/core"

export interface FeatureItemProps {
  icon?: string
  image?: IGatsbyImageData
  title: string
  emTitle: string | undefined
  text: string
  link: string
  textAlign: "left" | "right" | "center"
  embedId?: string
}

const rootCss: ColorSchemeCss = theme => ({
  display: `flex`,
  flexDirection: `column`,
  gap: theme.space[3],
})

const emTitleCss: ColorSchemeCss = theme => ({
  color: theme.colorScheme.dark,
})

const titleCss: ColorSchemeCss = theme => [
  itemTitleCss(theme),
  {
    color: theme.colors.grey[90],
    marginBottom: theme.space[2],
  },
]

const imageCss: ColorSchemeCss = theme => ({
  display: "block",
  marginBottom: theme.space[5],
  marginRight: "auto",
})

const learnMoreCss: ColorSchemeCss = theme => ({
  marginTop: `auto`,
  background: theme.colorScheme.dark,
  borderColor: theme.colorScheme.dark,

  "&:hover": {
    background: theme.colorScheme.hover,
    borderColor: theme.colorScheme.hover,
  },
})

export function FeatureItem({
  feature,
  breakTitle = false,
}: {
  feature: FeatureItemProps
  breakTitle: boolean
}): JSX.Element {
  const {
    icon,
    image,
    title,
    emTitle,
    text,
    link,
    textAlign,
    embedId,
  } = feature
  return (
    <div
      css={theme => [
        rootCss(theme),
        { alignItems: textAlign === "left" ? "start" : "center" },
      ]}
    >
      <div css={{ width: `100%` }}>
        {icon ? (
          <img
            src={icon}
            alt={`${title} ${emTitle} icon`}
            css={theme => [
              imageCss(theme),
              { marginLeft: textAlign === "left" ? "none" : "auto" },
            ]}
            role="presentation"
          />
        ) : null}
        {image ? (
          <GatsbyImage
            image={image}
            alt={`${title} ${emTitle} icon`}
            css={imageCss}
          />
        ) : null}
        {embedId ? (
          <VideoEmbed title={title} embedId={embedId} css={imageCss} />
        ) : null}
        {title || emTitle ? (
          <Heading css={theme => [titleCss(theme), { textAlign }]} as="h3">
            {title} {breakTitle && <br />}{" "}
            <span css={emTitleCss}>{emTitle}</span>
          </Heading>
        ) : null}
      </div>
      {text ? (
        <Text
          as="div"
          css={
            (theme => [
              itemCopyCss(theme),
              { textAlign },
            ]) as InterpolationWithTheme<Theme>
          }
          dangerouslySetInnerHTML={{
            __html: sanitizeHTML(text, {
              allowedTags: ["em", "strong", "a", "p"],
            }),
          }}
        />
      ) : null}

      {link ? (
        <LinkButton to={link} size="M" css={learnMoreCss}>
          Learn More
        </LinkButton>
      ) : null}
    </div>
  )
}
